<!--
 * @Author: Jerry
 * @Date: 2021-03-17 14:44:37
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-17 14:46:55
 * @Description: file content
-->
<template>
  <div style="text-align: center; margin-top: 100px">
    <i class="el-icon-success" style="color: green; font-size: 68px"></i>
    <p>{{ getQueryVariable('msg') }}</p>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    getQueryVariable(variable) {
      var query = decodeURI(window.location.search.substring(1));
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
</style>
